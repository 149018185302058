<template>
  <div tabindex="0" @keyup.enter="toggle()" @click="toggle()" :class="getClass">
    <div class="base-checkbox__box-container">
      <div :class="active ? 'active' : ''" class="base-checkbox__box"></div>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    rounded: {
      type: Boolean,
      required: false,
      default: false
    },
    checked: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      active: false
    }
  },
  watch: {
    checked(val) {
      this.active = val;
    }
  },
  methods: {
    toggle() {
      if (!this.disabled) {
        this.active = !this.active;
        this.$emit('toggled', this.active);
      }
    }
  },
  computed: {
    getClass() {
      const arr = ['base-checkbox'];
      if (this.rounded) arr.push('base-checkbox-rounded');
      return arr;
    }
  },
})
export default class BaseChecklist extends Vue {}
</script>

<style lang="scss">
@import '@/design';

.base-checkbox {
  display: flex;
  gap: 12px;
  margin: 12px 0;
}
.base-checkbox p {
  position: relative;
  top: 1px;
  padding: unset;
  margin: unset;
}
.base-checkbox__box {
  display: block;
  position: relative;
  height: 20px;
  width: 20px;
  border: 1px solid $gray-300;
  border-radius: calc(#{$base-radius} / 2);
  background: white;
  cursor: pointer;
}
.base-checkbox__box.active {
  border-color: $green-100;
  background: url(~@/assets/circular-green-tick.svg);
  background-color: $green-100;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 102%;
}
.base-checkbox-rounded .base-checkbox__box {
  border-radius: 100%;
}
</style>