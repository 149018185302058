<template>
  <div class="register-form">
    <form @submit.prevent>
      <fieldset class="register-form__fields">
        <BaseField :success="success" :error="formErrors.email">
          <BaseInput
            :disabled="loading || success"
            :model-value="formFields.email"
            @update:model-value="formFields.email = $event"
            name="Email address"
            autocomplete="email"
            aria-autocomplete="email"
            placeholder="Email address">
            <img class="register-form__input--success-icon" v-if="success" src="@/assets/circular-green-tick.svg" alt="success tick">
          </BaseInput>
        </BaseField>
        <BaseField :success="success" :error="passwordError">
          <BaseInput
            :disabled="loading || success"
            :model-value="formFields.password"
            @update:model-value="formFields.password = $event"
            name="Password"
            autocomplete="password"
            aria-autocomplete="password"        
            type="password"
            placeholder="Password">
            <img class="register-form__input--success-icon" v-if="success" src="@/assets/circular-green-tick.svg" alt="success tick">
          </BaseInput>
        </BaseField>
      </fieldset>
    </form>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BaseField from '@/components/BaseField.vue';
import BaseInput from '@/components/BaseInput.vue';
import client from '@/client';

@Options({
  components: { BaseInput, BaseField },
  props: {
    loading: Boolean,
    success: Boolean,
  },
  data() {
    return {
      formFields: {
        email: '',
        password: '',
      },
      formErrors: {
        email: '',
        password1: '',
        password2: ''
      }
    }
  },
  watch: {
    'formFields.email': function(val) {
      if (val) {
        this.formErrors.email = '';
      }
    },
    'formFields.password': function(val) {
      if (val) {
        this.formErrors.password1 = '';
        this.formErrors.password2 = '';
      }
    },
  },
  methods: {
    handleSubmit(name: string) {
      let firstName = '';
      let lastName = '';

      if (name) {
        const fullName = name.split(' ');
        firstName = fullName[0];
        lastName = fullName[fullName.length - 1];
      }
      
      return client
      .register(this.formFields.email, this.formFields.password, this.formFields.password, firstName, lastName)
      .then(response => {
        const user = {
          email: response.data.user.email,
          token: response.data.token,
          stripe_id: response.data.user.stripe_id
        };
        this.$store.commit('loginUser', user);
        return user;
      })
      .catch(error => {
        if (error.response === undefined) {
          console.error(error);
        } else if (error.response.status === 409) {
          this.formErrors.email = 'This email is already in use.';
        } else if (error.response.data) {
          for (const i in error.response.data) {
            this.formErrors[i] = error.response.data[i][0];
          }
        }
        throw error;
      });
    }
  },
  computed: {
    passwordError() {
      let error = '';
      if (this.formErrors.password1) error = this.formErrors.password1;
      if (this.formErrors.password2) error = this.formErrors.password2;
      return error;
    }
  },
})
export default class RegisterForm extends Vue {}
</script>

<style scoped lang="scss">
.register-form__fields {
  border: unset;
  display: flex;
  gap: 16px;
  padding: unset;
  margin: unset;
  flex-wrap: wrap;
}
.register-form__fields .base-field, 
.register-form__fields .base-input {
  flex: 1;
}
.register-form__input--success-icon {
  align-self: center;
  width: 16px;
  height: 16px;
}
</style>
