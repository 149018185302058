<template>
  <BaseNavigation>
    <div class="secure-server">
      <span>Secure server</span>
      <img :src="require('@/assets/lock.svg')" alt="Lock icon">
    </div>  
  </BaseNavigation>
  <div class="membership-view" @keyup.enter="isSubmitEnabled ? subscribeAndPay() : ''">
    <div class="membership-view__title-section">
      <div class="membership-view__title">
        <h1>Choose your monthly plan</h1>
        <h4>Plant native trees, fight climate change, revive destroyed lands, and erase your carbon footprint.</h4>
      </div>
      <img class="membership-view__earth" :src="require('@/assets/3d-trees.png')" alt="3D Trees">
    </div>
    <div class="membership-view__step-container">
      <BaseBadge>Step 1</BaseBadge>
      <h3>Select a plan that works for you:</h3>
    </div>
    <Plan :loading="formSubmitting" ref="plan"></Plan>
    <div class="membership-view__step-container">
      <BaseBadge>Step 2</BaseBadge>
      <h3 class="mr-auto">
        <template v-if="!showLoginForm">Create an account:</template>
        <template v-else>Log into your existing account:</template>
      </h3>
      <span v-if="!isLoggedIn" @click="showLoginForm = !showLoginForm" class="base-link">
        <template v-if="!showLoginForm">Already have an account? Log in.</template>
        <template v-else>Need an account? Register.</template>
      </span>
    </div>
    <RegisterForm v-if="!showLoginForm" :loading="formSubmitting" :success="isLoggedIn" ref="register"></RegisterForm>
    <LoginForm v-else :loading="formSubmitting" :success="isLoggedIn" ref="login"></LoginForm>
    <div class="membership-view__step-container">
      <BaseBadge>Step 3</BaseBadge>
      <h3>Set up your payment:</h3>
    </div>
    <Checkout :loading="formSubmitting" ref="checkout"></Checkout>
    <BaseCheckbox class="membership-view__checkbox" @toggled="checkedBoxes.termsAndConditions = $event">
      <p>I agree to the <a href="https://ourforest.io/terms-of-service" target="_blank" class="base-link">Terms & Conditions</a> and <a href="https://ourforest.io/privacy-policy" target="_blank" class="base-link">Privacy Policy</a>.</p>
    </BaseCheckbox>
    <BaseCheckbox class="membership-view__checkbox" @toggled="checkedBoxes.appUpdates = $event">
      <p>I agree to receive OurForest updates about my impact, important related news and other ways to help make a difference. You can unsubscribe later at any time.</p>
    </BaseCheckbox>
    <BaseButton
      type="submit"
      class="membership-view__submit"
      :loading="formSubmitting"
      loadingText="Processing"
      :disabled="!isSubmitEnabled"
      @click.prevent="isSubmitEnabled ? subscribeAndPay() : ''"
      text="Subscribe and pay"/>
    <div class="membership-view__faq">
      <h4>Can I change or cancel my plan?</h4>
      <p>Yes, you can change your plan at any time. If you'd like to cancel your subscription or switch to a new plan, you can do easily when editing your profile or messaging us directly.</p>
      <h4>Powered by Stripe</h4>
      <p>This page uses Stripe’s secure checkout. By using it you’re accepting their <a class="base-link" href="https://stripe.com/checkout/terms" target="_blank">Terms & Conditions</a> and <a class="base-link" href="https://stripe.com/privacy" target="_blank">Privacy Policy</a>.</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Plan from '@/components/Plan.vue';
import RegisterForm from '@/components/RegisterForm.vue';
import LoginForm from '@/components/LoginForm.vue';
import Checkout from '@/components/Checkout.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseBadge from '@/components/BaseBadge.vue';
import BaseNavigation from '@/components/BaseNavigation.vue';

@Options({
  title: 'Premium',
  components: {
    Plan,
    RegisterForm,
    LoginForm,
    Checkout,
    BaseButton,
    BaseCheckbox,
    BaseBadge,
    BaseNavigation
  },
  data() {
    return {
      checkedBoxes: {
        termsAndConditions: false,
        appUpdates: false,
      },
      formSubmitting: false,
      isLoggedIn: false,
      showLoginForm: false,
    }
  },
  computed: {
    isSubmitEnabled() {
      if (this.checkedBoxes.termsAndConditions && this.checkedBoxes.appUpdates && !this.formSubmitting) return true;
      else return false;
    }
  },
  methods: {
    subscribeAndPay() {
      this.formSubmitting = true;
      if (this.isLoggedIn) {
        this.$refs
        .checkout
        .handleCheckout(this.$store.state.user, this.$refs.plan.activePriceId)
        .catch(() => this.formSubmitting = false)
      } else {
        this.$refs[this.showLoginForm ? 'login' : 'register'].handleSubmit(this.$refs.checkout.formFields.name)
        .then((user: any) => {
          if (user && user.token && user.stripe_id) {
            this.isLoggedIn = true;
            this.$refs
            .checkout
            .handleCheckout(user, this.$refs.plan.activePriceId)
            .catch(() => {
              this.formSubmitting = false;
              this.$store.commit("failedCheckout", true);
            });
          }
        })
        .catch(() => this.formSubmitting = false)
      }
    },
  }
})
export default class Membership extends Vue {}
</script>

<style scoped lang="scss">
@import '@/design';
@import '@/design/secureIcon';

$membership-text-width: 560px;

.membership-view {
  display: flex;
  flex-direction: column;
  max-width: 1080px;
  margin: auto;
  padding: 40px 20px;
}
.membership-view h1 {
  margin-bottom: 16px;
}
.membership-view__step-container {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 48px 0;
  flex-wrap: wrap;
}
.membership-view__step-container h3 {
  margin: unset;
  padding: unset;
  min-width: 240px;
}
.membership-view__step-container .active {
  color: #66CC00;
}
.membership-view__submit {
  max-width: 320px;
  margin-top: 16px;
}
.membership-view__checkbox {
  max-width: $membership-text-width;
}
.membership-view__title {
  flex: 1;
  max-width: $membership-text-width;
}
.membership-view__faq {
  margin-top: 60px;
  padding: 40px 0;
  border-top: 1px solid $gray-300;
}
.membership-view__faq h4 {
  font-weight: $bold;
  margin-bottom: 4px;
}
.membership-view__faq p {
  margin: unset;
  padding-bottom: 40px;
}
.membership-view__title-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.membership-view__earth {
  max-height: 160px;
  height: 100%;
  margin-right: 80px;
}
</style>

