
import { Options, Vue } from 'vue-class-component';
import BaseInput from '@/components/BaseInput.vue';
import BaseField from '@/components/BaseField.vue';
import CountrySelect from '@/components/CountrySelect.vue';
import client from '@/client';

declare const Stripe: any;
const stripe: any = Stripe(process.env.VUE_APP_STRIPE_PK_LIVE);
const elements = stripe.elements();
const element = elements.create('card', {
  style: {
    base: {
      fontSize: '16px',
      fontWeight: '400',
      fontFamily: 'Circular, Fallback, sans-serif',
      '::placeholder': {
        color: '#797979',
      },      
    }
  }
});

@Options({
  components: { BaseInput, CountrySelect, BaseField },
  props: {
    loading: Boolean
  },
  data() {
    return {
      formFields: {
        name: '',
        country: '',
      },
      formErrors: {
        cardElement: ''
      },
      supportedCards: ['visa', 'mastercard', 'amex', 'discover', 'jcb', 'visaelectron', 'dinersclub'],
      isCardFocused: false
    }
  },
  methods: {
    handleCheckout(user: any, activePriceId: string) {
      const cardElement = elements.getElement("card");
      cardElement.update({ disabled: true });
      return client
      .createSubscription(user.token, { stripe_id: user.stripe_id, priceId: activePriceId })
      .then(async (response: any) => {
        const { error } = await stripe.confirmCardPayment(response.data.clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: this.formFields.name,
              email: user.email,
              address: { country: this.formFields.country }
            }
          }
        });
        if (error) throw error;
        this.$store.commit("failedCheckout", false);
        this.$router.push({ name: 'Discovery' });
        return response;
      })
      .catch((error: any) => {
        if (error.message && error.type === 'card_error') {
          this.formErrors.cardElement = error.message;
        }
        cardElement.update({ disabled: false });
        throw error;
      })
    }
  },
  mounted() {
    element.mount('#card-element');
  },
  computed: {
    getCardClass() {
      let classes = ['stripe-base-input'];
      if (this.loading) classes.push('disabled');
      return classes;
    }
  }
})
export default class Checkout extends Vue {}
