<template>
  <div :class="getClass">
    <select
      autocomplete="on"
      tabindex="0"
      :disabled="disabled"
      @focus.passive="isFocused = true"
      @blur.passive="isFocused = false"
      @change="updateSelectedCountryCode($event.target.value)"
      v-bind="$attrs">
      <option :selected="showPlaceholder" disabled>
        Select your country
      </option>
      <option
        v-for="country in countries"
        :key="country.alpha2Code"
        :selected="country.alpha2Code === selectedCountryCode"
        :value="country.alpha2Code">
          {{ country.name }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import client from '@/client';
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    disabled: Boolean
  },
  data() {
    return {
      countries: [],
      selectedCountryCode: null,
      isFocused: false
    }
  },
  mounted() {
    client
    .getClientCountry()
    .then(result => this.updateSelectedCountryCode(result.data.country_code))
    .catch(error => console.log(error))
    
    client
    .getCountries()
    .then(result => this.countries = result.data)
    .catch(error => console.log(error));

  },
  methods: {
    updateSelectedCountryCode(value: string) {
      this.selectedCountryCode = value;
      this.$emit('selectedCountryCode', this.selectedCountryCode);
    }
  },
  computed: {
    showPlaceholder() {
      return this.selectedCountryCode === null;
    },
    getClass() {
      let classes = ['country-select'];
      if (this.showPlaceholder) classes.push('placeholder');
      if (this.disabled) classes.push('disabled');
      if (this.isFocused) classes.push('focused');
      return classes;
    }
  }
})

export default class CountrySelect extends Vue {}
</script>

<style lang="scss">
@import '@/design';

.country-select {
  display: flex;
  flex: 1;
  min-width: 200px;
  height: 56px;
  background: white;
  border: 1px solid $gray-300;
  box-sizing: border-box;
  border-radius: $base-radius;
  overflow: hidden;
  padding: 0 12px;
}
.country-select.disabled,
.country-select select:disabled {
  background: $gray-100;
}
.country-select.focused {
  border-color: $green-100;
}
.country-select select {
  background: url(~@/assets/select-icon.svg) 100% 50% no-repeat !important;
  width: 100%;
  outline: 0;
  border: 0;
  font-size: 16px;
  font-family: inherit;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  border-radius: unset !important;
  -webkit-border-radius: unset !important;
  -moz-border-radius: unset !important;
}
.country-select.placeholder select {
  color: $gray-500;
}
</style>