
import { Options, Vue } from 'vue-class-component';
import BaseBadge from '@/components/BaseBadge.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';
import client from '@/client';

const carbonNeutralID  = 'prod_JolfNDurlBmDDG';
const carbonPositiveID = 'prod_JolgvKJuQlIpFD';
const carbonHeroID     = 'prod_JolguDmodaUP6k';

@Options({
  components: { BaseBadge, BaseCheckbox },
  props: {
    loading: Boolean
  },
  data() {
    return {
      products: {
        carbonNeutral: {},
        carbonPositive: {},
        carbonHero: {},
      },
      activeProduct: '',
      activePriceId: '',
      loadingProducts: false,
    }
  },
  methods: {
    selectProduct(key: string) {
      this.activeProduct = key;
      this.activePriceId = this.products[key].metadata.price_ID;
      this.savePlanSpecsToSessionStorage({
        trees_planted: this.products[key].metadata.trees_planted,
        carbon_removed: this.products[key].metadata.carbon_removed,
      })
    },
    setupProducts() {
      this.loadingProducts = true;
      client
      .getProducts()
      .then((result: any) => {
        this.loadingProducts = false;
        for (let i=0;i<result.data.length;i++) {
          switch (result.data[i].id) {
            case carbonNeutralID:
              this.products.carbonNeutral = result.data[i];
              break;
            case carbonPositiveID:
              this.products.carbonPositive = result.data[i];
              break;
            case carbonHeroID:
              this.products.carbonHero = result.data[i];
              break;
          }
        }
        this.selectProduct('carbonPositive');
      })
      .catch(error => console.log('error', error));
    },
    getFeatures(metadata: any) {
      const features: any = [];
      for (const key in metadata) {
        if (key.match(/feature_main_[0-9]/)) {
          const featureEmoji = metadata[`feature_emoji_${key.slice(-1)}`];
          const featureMain  = metadata[key];
          const featureDesc  = metadata[`feature_description_${key.slice(-1)}`];
          let feature = '';

          if (featureEmoji) feature += `<i>${featureEmoji}</i>`;
          if (featureMain)  feature += `<h5>${featureMain}</h5>`;
          if (featureDesc)  feature += `<span> – ${featureDesc}</span>`;

          features.push(feature);
        }
      }
      return features;
    },
    savePlanSpecsToSessionStorage(specs: any) {
      // we load these specs on the dashboard for a smoother experience
      sessionStorage.setItem('selectedPlanSpecs', JSON.stringify(specs));
    }
  },
  mounted() {
    this.setupProducts();
  }
})
export default class Plan extends Vue {}
