
import client from '@/client';
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    disabled: Boolean
  },
  data() {
    return {
      countries: [],
      selectedCountryCode: null,
      isFocused: false
    }
  },
  mounted() {
    client
    .getClientCountry()
    .then(result => this.updateSelectedCountryCode(result.data.country_code))
    .catch(error => console.log(error))
    
    client
    .getCountries()
    .then(result => this.countries = result.data)
    .catch(error => console.log(error));

  },
  methods: {
    updateSelectedCountryCode(value: string) {
      this.selectedCountryCode = value;
      this.$emit('selectedCountryCode', this.selectedCountryCode);
    }
  },
  computed: {
    showPlaceholder() {
      return this.selectedCountryCode === null;
    },
    getClass() {
      let classes = ['country-select'];
      if (this.showPlaceholder) classes.push('placeholder');
      if (this.disabled) classes.push('disabled');
      if (this.isFocused) classes.push('focused');
      return classes;
    }
  }
})

export default class CountrySelect extends Vue {}
