<template>
  <span class="base-badge">
    <slot></slot>
  </span>
</template>

<script>
export default {

}
</script>

<style lang="scss">
@import '@/design';

.base-badge {
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 1.2px;
  line-height: 20px;
  vertical-align: middle;
  text-transform: uppercase;
  background: #334a59;
  border-radius: 15px;
  color: white;
  padding: 2px 10px;
  position: relative;
  text-align: center;
  min-width: 70px;
  box-sizing: border-box;
}
.base-badge.primary {
  background: $green-100;
}
.base-badge.primary-animated {
  background: linear-gradient(-45deg, #66CC00, #64B60E, #088770, #6DD704);
  background-size: 400% 400%;
  animation: gradient 8s ease infinite;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
</style>