<template>
  <div class="checkout">
    <form @submit.prevent class="checkout__form">
      <div class="supported-cards">
        <img
          v-for="card in supportedCards"
          :key="card"
          :src="require(`@/assets/${card}.png`)"
          :alt="card">
      </div>
      <fieldset class="checkout__fields">
        <BaseInput
          :disabled="loading"
          :model-value="formFields.name"
          name="Full name"
          autocomplete="name"
          aria-autocomplete="name"
          @update:model-value="formFields.name = $event"
          placeholder="Full name">
        </BaseInput>
        <CountrySelect
          :disabled="loading"
          name="Country"
          autocomplete="country-name"
          aria-autocomplete="country-name"          
          @selectedCountryCode="formFields.country = $event">
        </CountrySelect>
      </fieldset>
      <fieldset class="checkout__fields card-element">
        <BaseField :error="formErrors.cardElement">
          <div id="card-element" :class="getCardClass"></div>
        </BaseField>
      </fieldset>
    </form>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BaseInput from '@/components/BaseInput.vue';
import BaseField from '@/components/BaseField.vue';
import CountrySelect from '@/components/CountrySelect.vue';
import client from '@/client';

declare const Stripe: any;
const stripe: any = Stripe(process.env.VUE_APP_STRIPE_PK_LIVE);
const elements = stripe.elements();
const element = elements.create('card', {
  style: {
    base: {
      fontSize: '16px',
      fontWeight: '400',
      fontFamily: 'Circular, Fallback, sans-serif',
      '::placeholder': {
        color: '#797979',
      },      
    }
  }
});

@Options({
  components: { BaseInput, CountrySelect, BaseField },
  props: {
    loading: Boolean
  },
  data() {
    return {
      formFields: {
        name: '',
        country: '',
      },
      formErrors: {
        cardElement: ''
      },
      supportedCards: ['visa', 'mastercard', 'amex', 'discover', 'jcb', 'visaelectron', 'dinersclub'],
      isCardFocused: false
    }
  },
  methods: {
    handleCheckout(user: any, activePriceId: string) {
      const cardElement = elements.getElement("card");
      cardElement.update({ disabled: true });
      return client
      .createSubscription(user.token, { stripe_id: user.stripe_id, priceId: activePriceId })
      .then(async (response: any) => {
        const { error } = await stripe.confirmCardPayment(response.data.clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: this.formFields.name,
              email: user.email,
              address: { country: this.formFields.country }
            }
          }
        });
        if (error) throw error;
        this.$store.commit("failedCheckout", false);
        this.$router.push({ name: 'Discovery' });
        return response;
      })
      .catch((error: any) => {
        if (error.message && error.type === 'card_error') {
          this.formErrors.cardElement = error.message;
        }
        cardElement.update({ disabled: false });
        throw error;
      })
    }
  },
  mounted() {
    element.mount('#card-element');
  },
  computed: {
    getCardClass() {
      let classes = ['stripe-base-input'];
      if (this.loading) classes.push('disabled');
      return classes;
    }
  }
})
export default class Checkout extends Vue {}
</script>

<style scoped lang="scss">
@import '@/design';
.checkout__form {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.checkout__fields {
  border: unset;
  display: flex;
  gap: 16px;
  padding: unset;
  margin: unset;
  flex-wrap: wrap;
}
.checkout__fields .base-input {
  flex: 1;
}
.stripe-base-input {
  display: block;
  border: 1px solid $gray-300;
  border-radius: $base-radius;
  height: 56px;
  box-sizing: border-box;
  background: white;
  transition: ease 0.2s;
  padding: 18px 12px;
  > div {
    width: 100%;
    height: 100%;
  }
}
.stripe-base-input.disabled {
  background: $gray-100;
}
.stripe-base-input.StripeElement--focus {
  border-color: $green-100;
}
.checkout__fields.card-element .base-field {
  flex: 1;
  margin-bottom: 16px;
}
.checkout__form .supported-cards {
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
  flex-wrap: wrap;
  img { 
    width: 100%;
    max-width: 48px;
    background: white;
  }
}
</style>
