<template>
  <div class="plan">
    <div 
      class="plan__product"
      :class="[key === activeProduct ? 'active' : '', loading ? 'disabled' : '']"
      @click.prevent="loading ? '' : selectProduct(key)"
      @focus.prevent="loading ? '' : selectProduct(key)"
      v-for="(product, key, index) in products"
      :tabindex="index + 1"
      :key="key">
      <BaseBadge v-if="index === 1" class="plan__product--most-popular primary-animated">Most popular</BaseBadge>
      <BaseCheckbox
        v-show="!loadingProducts"
        class="plan__checkbox"
        :rounded="true"
        :disabled="true"
        :checked="key === activeProduct"
      ></BaseCheckbox>
      <div class="plan__product-header">
        <template v-if="loadingProducts">
          <div class="skeleton-bar"/>
          <div class="skeleton-bar" style="width:75%;"/>
          <div class="skeleton-bar" style="width:25%;"/>
        </template>
        <h3>{{ product.name }}</h3>
        <div v-if="product.metadata" :class="index === 1 ? 'primary' : ''" class="plan__product-price">
          <h1>{{ product.metadata['price_USD'] }}</h1>
          <small>per month</small>
        </div>
        <p>{{ product.description }}</p>
      </div>
      <div class="plan__product-footer">
        <template v-if="loadingProducts">
          <div class="skeleton-bar"/>
          <div class="skeleton-bar" style="width:75%;"/>
        </template>
        <ul v-if="product.metadata">
          <li
            v-for="(feature, index) in getFeatures(product.metadata)"
            :key="index"
            v-html="feature"></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BaseBadge from '@/components/BaseBadge.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';
import client from '@/client';

const carbonNeutralID  = 'prod_JolfNDurlBmDDG';
const carbonPositiveID = 'prod_JolgvKJuQlIpFD';
const carbonHeroID     = 'prod_JolguDmodaUP6k';

@Options({
  components: { BaseBadge, BaseCheckbox },
  props: {
    loading: Boolean
  },
  data() {
    return {
      products: {
        carbonNeutral: {},
        carbonPositive: {},
        carbonHero: {},
      },
      activeProduct: '',
      activePriceId: '',
      loadingProducts: false,
    }
  },
  methods: {
    selectProduct(key: string) {
      this.activeProduct = key;
      this.activePriceId = this.products[key].metadata.price_ID;
      this.savePlanSpecsToSessionStorage({
        trees_planted: this.products[key].metadata.trees_planted,
        carbon_removed: this.products[key].metadata.carbon_removed,
      })
    },
    setupProducts() {
      this.loadingProducts = true;
      client
      .getProducts()
      .then((result: any) => {
        this.loadingProducts = false;
        for (let i=0;i<result.data.length;i++) {
          switch (result.data[i].id) {
            case carbonNeutralID:
              this.products.carbonNeutral = result.data[i];
              break;
            case carbonPositiveID:
              this.products.carbonPositive = result.data[i];
              break;
            case carbonHeroID:
              this.products.carbonHero = result.data[i];
              break;
          }
        }
        this.selectProduct('carbonPositive');
      })
      .catch(error => console.log('error', error));
    },
    getFeatures(metadata: any) {
      const features: any = [];
      for (const key in metadata) {
        if (key.match(/feature_main_[0-9]/)) {
          const featureEmoji = metadata[`feature_emoji_${key.slice(-1)}`];
          const featureMain  = metadata[key];
          const featureDesc  = metadata[`feature_description_${key.slice(-1)}`];
          let feature = '';

          if (featureEmoji) feature += `<i>${featureEmoji}</i>`;
          if (featureMain)  feature += `<h5>${featureMain}</h5>`;
          if (featureDesc)  feature += `<span> – ${featureDesc}</span>`;

          features.push(feature);
        }
      }
      return features;
    },
    savePlanSpecsToSessionStorage(specs: any) {
      // we load these specs on the dashboard for a smoother experience
      sessionStorage.setItem('selectedPlanSpecs', JSON.stringify(specs));
    }
  },
  mounted() {
    this.setupProducts();
  }
})
export default class Plan extends Vue {}
</script>

<style lang="scss">
@import '@/design';
@import '@/design/loadingSkeleton.scss';
.plan {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  padding: 16px 0;
}
.plan__product {
  flex: 1;
  background: white;
  border: 1px solid $gray-300;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
  min-height: 368px;
  min-width: 220px;
  padding: 24px 1px;
  position: relative;
  text-align: center;
  transition: transform ease 0.3s;
}
.plan__product.disabled {
  cursor: not-allowed;
  background: $gray-100;
}
.plan__product.active {
  border: 2px solid $green-100;
  padding: 23px 0;
  transform: translateY(-8px);
}
.plan__product-header {
  border-bottom: 1px solid $gray-300;
  min-height: 226px;
  box-sizing: border-box;
  margin-left: -1px;
  margin-right: -1px;
  padding: 16px;
}
.plan__product-price {
  padding: 8px 0;
  h1 {
    margin: unset;
    padding: unset;
    line-height: 100%;
  }
  small {
    font-weight: $semibold;
  }
}
.plan__product-price.primary h1,
.plan__product-price.primary small {
  color: $green-100;
}
.plan__product-footer {
  padding: 20px 16px;
  padding-bottom: 8px;
  ul {
    display: flex;
    flex-direction: column;
    padding: unset;
    margin: unset;
    gap: 12px;
    list-style-type: none;
  }
  li {
    margin: 0;
  }
  li i {
    display: inline-block;
    font-style: unset;
    margin-right: 8px;
  }
  li h5 {
    display: inline;
    color: $black;
    font-weight: $semibold;
    margin-bottom: 4px;
  }
  li span {
    display: inline;
    color: $gray-500;
  }
}
.plan__product--most-popular {
  position: absolute;
  top: -15px;
  right: 16px;
  border-radius: 3px;
  padding: 4px 10px;
}
.plan__product.active .plan__product--most-popular {
  top: -16px;
  right: 15px;
}
.plan__product:focus {
  outline: unset;
}
.plan__checkbox {
  position: absolute;
  top: 8px;
  left: 16px;
}
@media(max-width: 425px) {
  .plan__product.active {
    transform: unset;
  }
}
</style>
