
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    rounded: {
      type: Boolean,
      required: false,
      default: false
    },
    checked: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      active: false
    }
  },
  watch: {
    checked(val) {
      this.active = val;
    }
  },
  methods: {
    toggle() {
      if (!this.disabled) {
        this.active = !this.active;
        this.$emit('toggled', this.active);
      }
    }
  },
  computed: {
    getClass() {
      const arr = ['base-checkbox'];
      if (this.rounded) arr.push('base-checkbox-rounded');
      return arr;
    }
  },
})
export default class BaseChecklist extends Vue {}
