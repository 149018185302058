
import { Options, Vue } from 'vue-class-component';
import Plan from '@/components/Plan.vue';
import RegisterForm from '@/components/RegisterForm.vue';
import LoginForm from '@/components/LoginForm.vue';
import Checkout from '@/components/Checkout.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseBadge from '@/components/BaseBadge.vue';
import BaseNavigation from '@/components/BaseNavigation.vue';

@Options({
  title: 'Premium',
  components: {
    Plan,
    RegisterForm,
    LoginForm,
    Checkout,
    BaseButton,
    BaseCheckbox,
    BaseBadge,
    BaseNavigation
  },
  data() {
    return {
      checkedBoxes: {
        termsAndConditions: false,
        appUpdates: false,
      },
      formSubmitting: false,
      isLoggedIn: false,
      showLoginForm: false,
    }
  },
  computed: {
    isSubmitEnabled() {
      if (this.checkedBoxes.termsAndConditions && this.checkedBoxes.appUpdates && !this.formSubmitting) return true;
      else return false;
    }
  },
  methods: {
    subscribeAndPay() {
      this.formSubmitting = true;
      if (this.isLoggedIn) {
        this.$refs
        .checkout
        .handleCheckout(this.$store.state.user, this.$refs.plan.activePriceId)
        .catch(() => this.formSubmitting = false)
      } else {
        this.$refs[this.showLoginForm ? 'login' : 'register'].handleSubmit(this.$refs.checkout.formFields.name)
        .then((user: any) => {
          if (user && user.token && user.stripe_id) {
            this.isLoggedIn = true;
            this.$refs
            .checkout
            .handleCheckout(user, this.$refs.plan.activePriceId)
            .catch(() => {
              this.formSubmitting = false;
              this.$store.commit("failedCheckout", true);
            });
          }
        })
        .catch(() => this.formSubmitting = false)
      }
    },
  }
})
export default class Membership extends Vue {}
